
  import { Component, Prop, Vue } from "vue-property-decorator";
  import Modal from "@/components/Modal.vue";
  import { translations } from "@/mixins";
  
  @Component({
    components: {
      Modal,
    },
    mixins: [translations],
  })
  export default class WebAddressSetModal extends Vue {
    @Prop({ default: false }) open!: boolean;
    @Prop ({ default: ''}) msg!: any
  
    get isOpen(): boolean {
      return this.open;
    }
  
    set isOpen(newVal: boolean) {
      if (!newVal) {
        this.$emit("modal:close", false);
      }
    }
  
    closeLogo(e: any) {
      this.$emit("modalClose", e);
    }
    confirm() {
        this.$emit("modalConfirm");
    }
  }
  