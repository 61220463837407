
import {
  Form,
  Alert,
  Input,
  Row,
  Col,
  InputNumber,
  FormItem,
  Select,
  Option,
  Checkbox,
  Radio,
  RadioGroup,
  Button,
  Table,
  TableColumn,
  Dialog
} from 'element-ui'
import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator"
import {Category, Integrations, Location} from '@/services/SOLO'
import {translations} from '@/mixins'
import Translations from '../../../Translations.vue'
import { mapGetters, mapMutations } from 'vuex'
import {
  ValidationObserver,
  configure,
  ValidationProvider,
} from "vee-validate"
import Multiselect from 'vue-multiselect'
import { required, email } from "vee-validate/dist/rules";

import IntegrationService from "@/services/SOLO/Integrations.service";
import {ElButton} from "element-ui/types/button";

@Component({
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Form.name]: Form,
    [Radio.name]: Radio,
    [Alert.name]: Alert,
    [Input.name]: Input,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [FormItem.name]: FormItem,
    [Checkbox.name]: Checkbox,
    [RadioGroup.name]: RadioGroup,
    [InputNumber.name]: InputNumber,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    Translations,
    ValidationProvider,
    Multiselect
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
      userRole: "account/getUserRole",
    })
  },
  methods: {
    ...mapMutations({
        setIsRefresh: 'page/setIsRefresh'
    })
  },
  filters: {
    formTitle(str: any) {
      return str.split('-').map((item: any) => {
        return item.charAt(0).toUpperCase() + item.substring(1);
      }).join(' ');
    },
    fieldTitle(str: any) {
      return str.split('_').map((item: any) => {
        return item.charAt(0).toUpperCase() + item.substring(1);
      }).join(' ');
    },
    capitalizeFirstLetter(str: any) {
      if (typeof str !== 'string') return ''
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },
  mixins: [translations],
})

export default class IntegratedItems extends Vue {
  disabled: boolean = false
  confirm!: Function
  translate!: Function
  getLocale!: any
  fieldTitle!: any
  formTitle!: any
  capitalizeFirstLetter!: any
  dynamicMenuID: any = ''
  @Prop() currentIntegrations?: Array<Object>
  userRole: any
  isOpen: boolean = false
  items: any = []
  sdm_categories: any = []
  foodicsF5Menu: any = []
  foodicsMenu: any = []
  $notify: any
  integratedCollection?: Array<Object>
  formElementId: any = ''
  formElements: any = {
    options: {
      address_types: {
        home: '',
        company: '',
        school: ''
      },
      config: {
        categories: '',
        fields: {
          items: '',
          modifiers: '',
          modifierGroups: '',
          location: '',
        },
        sources: {
          Web: '',
          iOS: '',
          Android: '',
        }
      },
      concept_id: '',
      extra_items_deliver: '',
      language: '',
      license_code: '',
      menu_id: '',
      menu_template_id: '',
      order_mode_deliver: '',
      order_mode_pickup: '',
      password_salt: '',
      pay_sub_type_card_on_delivery: '',
      pay_sub_type_cash: '',
      pay_type_card_on_delivery: '',
      pay_type_cash: '',
      request_code: '',
      service_charge: '',
      tender_id_card: '',
      tender_id_card_on_delivery: '',
      tender_id_cash: '',
      wsdl_url: '',
      payment_methods: '',

    }
  }

  // foodicsF5MenuCat = [
  //   { name: 'Sync Name', value: 'name' },
  //   { name: 'Sync Enabled', value: 'enabled' },
  //   { name: 'Sync Image URI', value: 'image_uri' },
  //   { name: 'Sync Display Order', value: 'display_order' },
  // ]
  foodicsF5MenuCat = ['name','enabled','image_uri','display_order']
  SDMItem = ['name','enabled','display_order','description','price']
  SDMModifier = ['name','enabled','price','calorie_count'];
  SDMModifierGroup = ['name','enabled'];
  SDMLocation = ['lat','lng','name','country','telephone','promised_time_delta_pickup','promised_time_delta_delivery','delivery_enabled','delivery_charge','opening_hours','status','delivery_zones'];
  // foodicsF5MenuItem = [
  //   { name: 'Sync Name', value: 'name' },
  //   { name: 'Sync Enabled', value: 'enabled' },
  //   { name: 'Sync Image URI', value: 'image_uri' },
  //   { name: 'Sync Display Order', value: 'display_order' },
  //   { name: 'Sync Description', value: 'description' },
  //   { name: 'Sync Price', value: 'price' },
  //   { name: 'Sync Calories', value: 'calories' },
  // ]
  foodicsF5MenuItem = ['name','enabled','image_uri','display_order','description','price','calories']
  // foodicsF5MenuIngredient = [
  //   { name: 'Sync Name', value: 'name' },
  //   { name: 'Sync Enabled', value: 'enabled' },
  // ]
  foodicsF5MenuIngredient = ['name','enabled']
  // foodicsF5MenuModifier = [
  //   { name: 'Sync Name', value: 'name' },
  //   { name: 'Sync Enabled', value: 'enabled' },
  //   { name: 'Sync Price', value: 'price' },
  //   { name: 'Sync Calorie Count', value: 'calorie_count' },
  // ]
  foodicsF5MenuModifier = ['name','enabled','price','calorie_count']
  // foodicsF5MenuLocation = [
  //   { name: 'Sync Latitute', value: 'lat' },
  //   { name: 'Sync Longitude', value: 'lng' },
  //   { name: 'Sync Name', value: 'name' },
  //   { name: 'Sync Country', value: 'country' },
  //   { name: 'Sync Telephone', value: 'telephone' },
  //   { name: 'Sync Promised Time Delta Pickup', value: 'promised_time_delta_pickup' },
  //   { name: 'Sync Promised Time Delta Delivery', value: 'promised_time_delta_delivery' },
  //   { name: 'Sync Delivery Enabled', value: 'delivery_enabled' },
  //   { name: 'Sync Delivery Charge', value: 'delivery_charge' },
  //   { name: 'Sync Opening Hours', value: 'opening_hours' },
  //   { name: 'Sync Status', value: 'status' },
  //   { name: 'Sync Delivery Zones', value: 'delivery_zones' },
  // ]
  foodicsF5MenuLocation = ['lat','lng','name','country','telephone','promised_time_delta_pickup','promised_time_delta_delivery','delivery_enabled','delivery_charge','opening_hours','status','delivery_zones']
  // foodicsF5MenuModfierGroup = [
  //   { name: 'Sync Name', value: 'name' },
  //   { name: 'Sync Enabled', value: 'enabled' },  
  // ]
  foodicsF5MenuModfierGroup = ['name','enabled']
  modifiers = [
    {text: 'Name', value: 'name'},
    {text: 'Price', value: 'price'},
    {text: 'Minimum', value: 'minimum'},
    {text: 'Maximum', value: 'maximum'},
    {text: 'Enabled', value: 'enabled'},
  ]
  locations = [
    {text: 'Latitude', value: 'lat'},
    {text: 'Longitude', value: 'long'},
    {text: 'Opening Hours', value: 'opening_hours'},
  ]
  modifierGroups = [
    {text: 'Name', value: 'name'},
    {text: 'Minimum', value: 'minimum'},
    {text: 'Maximum', value: 'maximum'},
  ]
  getPaymentMethods: any = []
  getFilterPaymentMethods: any = []
  getDeliveryCharges: any = []
  getPaymentMethod: any = ''
  search: any = ''
  branches: any = []
  locationsArr: any = []
  parentBranches: any = []
  branchArr: any = []
  newBranches: any = []
  finalBranches: any = []
  files: any = {}
  locale: string = ''
  setIsRefresh!: Function

  @Watch('currentIntegrations', {deep: true})
  onCurrentIntegrationsChanged(newVal: any) {
    this.items = newVal;
    console.log('check integration', this.items);
  }
  
  async mounted() {
    this.getFoodicsPaymentMethods()
    this.getFoodicsDeliveryCharges()
    await this.getIntegrationLocation()
  }

  beforeClose(done: any) {
    this.branches = []
    this.isOpen = false
    done()
  }

  customLabel1 (option: any) {

    const str2 = option.charAt(0).toUpperCase() + option.slice(1);

    return str2;
  }

  closeModal() {
    this.branches = []
    this.isOpen = false
    this.$emit("getIntegrations");

  }

  termAgree(name: any) {
    return name === 'I agree to the <a href=\"https://skylinedynamics.com\">Terms and Conditions</a>' ? 'I agree to the Terms and Conditions' : ''
  }

  addRow() {
    this.disabled = true
    this.filteredBranches.unshift({
      id: '',
      name: '',
    })
  }

  removeRow(id: any) {
    console.log("check bracnhes", this.filteredBranches); 
    console.log("check id", id);

    const brnch = this.filteredBranches.find((branch: any) => branch.id === id);
    if (!brnch) {
      console.error(`Location with ID ${id} not found in array`);
      return this.filteredBranches;
    }    
    if(brnch) {

      // return this.filteredBranches.filter((branch: any) => branch.id !== id);
      if(brnch.hasOwnProperty('attributes')) {
          const index = this.filteredBranches.findIndex((item: any) => item.id === id);
          
          if (index !== -1) {
            this.filteredBranches.splice(index, 1);
          }
          return this.filteredBranches;
      } else {
          const index = this.filteredBranches.findIndex((item: any) => item.id === id);
            if (index !== -1) {
              this.filteredBranches.splice(index, 1);
            }
            return this.filteredBranches;
      }
    } 
  }

  getIntegrationLocation(page: number = 1) {
  Location.getAllLocations(page).then((response: any) => {
    if (response.data && Array.isArray(response.data.data)) {
      this.parentBranches = [...this.parentBranches, ...response.data.data];
      this.branches = this.parentBranches;
      if (
        response.data.meta &&
        response.data.meta.pagination &&
        response.data.meta.pagination.current_page <
          response.data.meta.pagination.total_pages
      ) {
        this.getIntegrationLocation(response.data.meta.pagination.current_page + 1);
      }
    }
  });
}

  handleDelete(index: any, data: any, branches: any) {
    this.disabled = false
    branches.splice(index, 1)
    Location.removeLocation(data.id).then((response: any) => {
    })
  }

  getProviderItem(data: any) {
    /* @ts-ignore */
    this.locale = this.getLocale == 'en_US' ? 'en-us' : 'ar-sa'
    this.isOpen = true
    this.formElementId = data.id
    this.formElements = data.attributes

    let foodLogistic: any = [];

    this.items.map((v: any) => {
        if (v.attributes.type == 'food-logistics') {
          foodLogistic.push(v.attributes.provider);
        }
    })

    if(foodLogistic.includes(this.formElements.provider)) {
      this.locationsArr = data.attributes.options.branches ? JSON.parse(data.attributes.options.branches) : []
    }

    console.log("provider item", this.formElements.options.menu_id);

    this.dynamicMenuID = this.formElements.options.menu_id;
    this.getSdmCategories();

    console.log("menu id", this.dynamicMenuID);


    if (this.formElements.type === 'pos') {
      if (this.formElements.provider === 'sdm') {
        this.formElements.options.address_types = JSON.parse(this.formElements.options.address_types)
      }
      if (this.formElements.provider === 'foodics-f5') {
        this.formElements.options.fields = JSON.parse(this.formElements.options.fields)
        this.formElements.options.payment_methods = JSON.parse(this.formElements.options.payment_methods)
        this.getFoodicsF5Menu()
      } else if (this.formElements.provider === 'foodics') {
        this.formElements.options.config = JSON.parse(this.formElements.options.config)
        this.getFoodicsMenu()
      } else {
        this.formElements.options.config = JSON.parse(this.formElements.options.config)
        this.getSdmCategories();
      }
    } else if (this.formElements.type === 'pos-disabled') {
      if (this.formElements.provider === 'foodics-f5') {
        this.formElements.options.fields = JSON.parse(this.formElements.options.fields)
        this.getFoodicsF5Menu()
      }
    } else if (this.formElements.type === 'payment') {
      if (this.formElements.provider === 'foodics-online') {
        this.formElements.options.config = JSON.parse(this.formElements.options.config)
        this.getFoodicsF5Menu()
      }
    }
  }

  async getSdmCategories() {
    await Category.integrationCategoriesInteg().then((response: any) => {
      console.log("check sdm categories", response.data.data);
      this.sdm_categories = response.data.data;
      // for (let key in this.formElements.options.config.categories) {
      //   this.formElements.options.config.categories[key] = this.categories[key].name
      // }
      console.log("filter cat", this.sdm_categories);
    })
  }

  getFoodicsF5Menu() {
    for (let key in this.formElements.options.fields) {
      this.foodicsF5Menu[key] = this.formElements.options.fields[key]
    }
  }

  getFoodicsMenu() {
    for (let key in this.formElements.options.config) {
      this.foodicsMenu[key] = this.formElements.options.config[key]
    }
  }

  buildFormData(formData: FormData, data: any, parentKey: string = '') {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;

      formData.append(parentKey, value);
    }
  }

  handleFile(event: any, key: string) {
    this.files[key] = event.target.files[0];
    this.formElements.options[key] = '';
  }

  async update() {

    let StopSubmission = false;

    let provider = this.formElements.provider

    let formPayload: any = {}
    let updatePayload: any = {}
    let payload: any = []
    let set: any = []

    let foodLogistic: any = [];

    this.items.map((v: any) => {
        if (v.attributes.type == 'food-logistics') {
          foodLogistic.push(v.attributes.provider);
        }
    })

    if(foodLogistic.includes(this.formElements.provider)) {
      this.filteredBranches.map((v: any, k: any) => {
        this.branchArr[k] = `"${v.id}":"${v.value}"`
        // Check if the constructed value contains 'undefined' or id is empty
        if (v.value === undefined || v.id === "") {
          StopSubmission = true;
        }
        this.formElements.options.branches = `{${this.branchArr}}`
      })
       // Stop form submission if the flag is set
      if (StopSubmission) {
        this.branchArr = [];
        this.$notify({
            title: "Integration Provider Failed!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Please check the location or branch ID that is not empty!",
            type: "danger",
            icon: "fas fa-bomb",
          });
        return; // Exit the function or handle the stop accordingly
      }
    }


    for (let key in this.formElements.options) {
      set = {
        'key': key,
        'value': typeof (this.formElements.options[key]) === 'object' ? JSON.stringify(this.formElements.options[key]) : this.formElements.options[key]
      }
      payload.push(set)
    }



    updatePayload['provider'] = this.formElements.provider
    updatePayload['type'] = this.formElements.type
    updatePayload['options'] = payload
    delete this.formElements
    formPayload = {...updatePayload, ...this.formElements}

    if (this.files.length != 0) {
      formPayload.files = this.files;
      const formData = new FormData();
      this.buildFormData(formData, formPayload);
      formPayload = formData;
    }

    await Integrations.store(provider, formPayload)
        .then((response) => {
          this.isOpen = false
          this.$notify({
            title: "Integration Provider Updated!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Integration provider has been successfully updated.",
            type: "success",
            icon: "fas fa-check",
          });
          this.formElements = {};
          formPayload = {};
          location.reload()
        })
        .catch((err) => {
          this.isOpen = false
          this.$notify({
            title: "Integration Provider Failed!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Integration provider update failed.",
            type: "danger",
            icon: "fas fa-bomb",
          });
          // location.reload();
        })
  }

  async deleteIntegration(integration_id: any) {

    if (!window.confirm(`Are you sure want to delete?`)) {
      return;
    }

    await Integrations.uninstall(integration_id)
        .then((response: any) => {
          let targetIndex = this.items.map((i: any) => {
            return i.id
          }).indexOf(integration_id)
          this.items.splice(targetIndex, 1)
          this.isOpen = false
          this.$notify({
            title: this.translate("INTEGRATION DELETED!"),
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: this.translate(`The ${this.formElements.provider} integration is now deleted.`),
            type: "success",
            icon: "fas fa-check",
          })
          // location.reload();
          this.setIsRefresh(1)
          this.$emit("getIntegrations");

        })
  }

  getFoodicsPaymentMethods() {
    Integrations.getPaymentMethods()
        .then((response: any) => {
          this.getPaymentMethods = response.data.data;
          this.getPaymentMethods.unshift({id: 'none', name : 'None'});
          this.getFilterPaymentMethods = response.data.data;
        })
  }

    get filterPaymentMethods() {
    if (this.getFilterPaymentMethods.length) {
      return this.getFilterPaymentMethods.filter((include: any) => {
        return include.code === "sololoyalty";
      });
    }

    return [];
  }

  getFoodicsDeliveryCharges() {
    Integrations.getDeliveryCharges()
        .then((response: any) => {
          this.getDeliveryCharges = response.data.data
        })
  }

  // from @Watch to computed to make the data reactive to changes
  get filteredBranches() {
    this.disabled = true
    let tempBranch: any = []
    let finalBranch: any = []
    Object.entries(this.locationsArr).map((v: any, k: any) => {
      tempBranch = this.parentBranches.filter((b: any) => {
        if (v[0] === b.id) {
            b.value = v[1]
          return finalBranch.push(b)
        }
      })
      // finalBranch.push(tempBranch[0])
    })
    this.branches = finalBranch
    return this.branches.filter((data: any) => !this.search || data.attributes.name.toLowerCase().includes(this.search.toLowerCase()));
  }
}
