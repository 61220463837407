
import { Select, Option } from "element-ui";
import { Menu as MenuApi, Location, Concept, Category as CategoryApi } from "@/services/SOLO";
import {
  Category as CategoryModel,
  Location as LocationModel,
  Application as ApplicationModel,
  ItemAttributes as ItemAttributesModel,
  CatAttributes as CatAttributesModel,
} from "@/models";
import { Imagetype } from "@/interfaces/SOLO";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { eventHandler, translations } from "@/mixins";
import {
  ValidationObserver,
  configure,
  ValidationProvider,
} from "vee-validate";
import { ElNotification } from "element-ui/types/notification";
import { required, email } from "vee-validate/dist/rules";
import { mapGetters } from "vuex";

interface objKey {
  [x: string]: string | number;
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationProvider,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
      getConceptSettings: 'account/getConceptSettings',

    }),

  },
  mixins: [eventHandler, translations],
})
export default class ItemUpdate extends Vue {
  getConceptSettings!: any
  items: Array<any> = [];
  selectedLocations: any = [];
  selectedApplications: any = [];
  defaultLocations: Array<LocationModel> = [];
  defaultApplications: Array<ApplicationModel> = [];
  locations: Array<LocationModel> = [];
  applications: Array<any> = [];
  arrayLang: Array<string> = [];
  itemNames: objKey = {};
  itemDescs: objKey = {};
  imageType: number = 1;
  $notify: any;
  numOfLoadedData: number = 0;
  file: any;
  stockImages: any = [];
  searchStock: any = '';
  isLoading: Boolean = false;
  imageTypes: Array<Object> = [
    {
      value: 0,
      text: "Image URL",
    },
    {
      value: 1,
      text: "Upload Image",
    },
    {
      value: 2,
      text: "Stock Images"
    }
  ];
  waiting!: Function;
  restore!: Function;
  getLanguages!: Function
  getLang! : Function;
  getLocale!: any;
  catItem: ItemAttributesModel = {
    name: {},
    description: {},
    code: '',
    price: '',
    "display-order": 1,
    calories: '',
    'person-count-per-serving': 1,
    'calorie-count': '',
    'image-uri': '',
    enabled: 1,
    'disable-for-pickup': 0,
    'disable-for-delivery': 0,
    barcode: ''
  }

  $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
    file: any;
  };

  public translate!: Function;

  @Watch("imageType", {deep: true, immediate: true})
  onChangeGetSotckImages(data: any) {
      if(data === 2) {
        this.getStockImages();
      }
  }
  @Watch("file", { immediate: true, deep: true })
  onFileChange(val: any) {
  }
  created() {
    // this.arrayLang = this.getLanguages().sort().reverse();
  }

  mounted() {
    this.getApps();
    this.getLocations();
    /* @ts-ignore */
        this.arrayLang.push(this.getConceptSettings.attributes['primary-language']);
    /* @ts-ignore */
    if (this.getConceptSettings.attributes['secondary-language']) {
      /* @ts-ignore */
      this.arrayLang.push(this.getConceptSettings.attributes['secondary-language']);
    }
  }

  get categoryItemData(): CatAttributesModel {
    let descCount = 0;
    for(let i in this.arrayLang) {
      if (this.catItem.description[this.arrayLang[i]]) {
        descCount = descCount + 1;
      }
    }
    if (!descCount) {
      this.catItem.description = null;
    }
    return this.catItem;
  }

  get categoryItemForm(): FormData {
    console.log('this.catItemthis.catItemthis.catItem: ', this.catItem);
    let formData = new FormData()
    for(let i in this.arrayLang) {
      formData.append(`name[${this.arrayLang[i]}]`, this.catItem.name[this.arrayLang[i]])
      formData.append(`description[${this.arrayLang[i]}]`, this.catItem.description[this.arrayLang[i]] || '')
    }
    formData.append('code', this.catItem.code)
    formData.append('barcode', this.catItem.barcode)
    formData.append('price', this.catItem.price)
    formData.append('display-order', this.catItem['display-order'].toString())
    formData.append('calories', this.catItem.calories)
    formData.append('person-count-per-serving', this.catItem['person-count-per-serving'].toString())
    formData.append('calorie-count', this.catItem['calorie-count'])
    formData.append('enabled', this.catItem.enabled.toString())
    formData.append('disable-for-pickup', this.catItem['disable-for-pickup'].toString())
    formData.append('disable-for-delivery', this.catItem['disable-for-delivery'].toString())
    if (this.$refs.file.files[0]) {
      formData.append('image', this.$refs.file.files[0])
    }
    return formData
  }

  get isDisabledForPickup(): Boolean {
    return this.catItem['disable-for-pickup'] === 1 ? true : false;
  }

  set isDisabledForPickup(newVal: Boolean) {
    this.catItem['disable-for-pickup'] = newVal ? 1 : 0
  }

  get isDisabledForDelivery(): Boolean {
    return this.catItem['disable-for-delivery'] === 1 ? true : false;
  }

  set isDisabledForDelivery(newVal: Boolean) {
    this.catItem['disable-for-delivery'] = newVal ? 1 : 0
  }

  get isEnabled(): Boolean {
    return this.catItem.enabled === 1 ? true : false;
  }

  set isEnabled(newVal: Boolean) {
    this.catItem.enabled = newVal ? 1 : 0;
  }


  getApps() {
    Concept.find().then((response: any) => {
      this.applications = response.data.data.map((data: any) => {
        return {
          id: data.id.toString(),
          label: data.label,
        };
      });
    });
  }
  selectStockImage(stockImage: any) {
    this.catItem['image-uri'] = stockImage;
  }
  searchStockImages(e: any) {
    this.isLoading = true;
      CategoryApi.searchStockImgs(this.searchStock)
      .then((response) => {
        this.stockImages = response.data.data;
        this.isLoading = false;
        console.log("search stock images", this.stockImages);
      })
  }
  getStockImages() {
    CategoryApi.getStockImgs()
      .then((response) => {
        this.stockImages = response.data.data;
        console.log("stock images", this.stockImages);
      })
  }

  checkSize(text: any) {

    if(this.getLocale === 'en_US') {
        if(text === 'The image field size must be less than 2000KB') {
          return 'The image file size must be less than 2MB'
        }
    }
    if(text === 'The image field size must be less than 2000KB') {
          return 'يجب أن يكون حجم ملف الصورة أقل من 2 ميغا بايت'
    }

  }


  getLocations() {
    Location.fetchLocations().then((response: any) => {
      this.locations = response.data.data;
    });
  }

  filesChange(e: any, errors: any) {
    if (typeof e.target.files[0] != undefined) {
      this.file = e.target.files[0];
    }
  }

  createCategoryItem(e: any, reset: any) {

    let defaultText = e.target.innerHTML
    e.target.innerHTML = '<i class="fas fa-spinner fa-spin"></i> Creating Category Item'
    e.target.disabled = true

    const payload = !this.imageType || this.imageType === 2 ? this.categoryItemData : this.categoryItemForm
    const content = !this.imageType || this.imageType === 2 ? 'application/json' : 'multipart/form-data'

    CategoryApi.createItem(payload, this.$route.params.id, this.$route.params.catId, content)
      .then(response => {
        this.$notify({
          title: "CATEGORY ITEM SAVED!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Category item has been saved successfully",
          type: "success",
          icon: "fas fa-check",
        });
        e.target.innerHTML = defaultText
        e.target.disabled = false

        // reset headers
        CategoryApi.headers['Content-Type'] = 'application/json'
        this.$emit('item:created')
        this.$emit('modal:update', false);
        this.$router.push({
            path: `/menus/${this.$route.params.id}/categories/${this.$route.params.catId}/1`,
        })
        reset()
      })
      .catch(err => {
        e.target.innerHTML = defaultText
        e.target.disabled = false
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Something went wrong, please try again!",
          type: "danger",
          icon: "fas fa-bomb",
        });
      })
  }
}
