import { render, staticRenderFns } from "./itemCreate.vue?vue&type=template&id=5871b0c0&scoped=true"
import script from "./itemCreate.vue?vue&type=script&lang=ts"
export * from "./itemCreate.vue?vue&type=script&lang=ts"
import style0 from "./itemCreate.vue?vue&type=style&index=0&id=5871b0c0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5871b0c0",
  null
  
)

export default component.exports