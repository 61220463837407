
import Translations from "./components/Translations.vue";
import {Application, QueueBuster as Queue} from "@/models";
import {
  Form,
  Alert,
  Input,
  Col,
  InputNumber,
  FormItem,
  Select,
  Option,
  Checkbox,
  Radio,
  RadioGroup,
  Button,
} from "element-ui";
import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator";
import { Location, Concept, Applications, Coupon, QueueBuster as QueueBusterApi } from "@/services/SOLO";
import VueQrious from "vue-qrious";
import VueHtml2pdf from "vue-html2pdf";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import {notificationAlerts, translations} from "@/mixins";
import html2pdf from 'html2pdf.js'
import {mapGetters} from 'vuex'


interface ApplicationAttributesObject {
  "application-type": string;
  "custom-domain": string;
  label: string;
  hosts: string;
}

interface ApplicationObject {
  id: string;
  attributes: ApplicationAttributesObject;
}

interface FormObject {
  branch: string;
  branchCode: string;
  branchLogo: string;
  qr_type: string;
  qrCodeCount: number;
  printLogo: boolean;
  printTable: boolean;
  printBranch: boolean;
  to: number;
  from: number;
  "web-uri": string;
}

@Component({
  components: {
    Translations,
    [Col.name]: Col,
    [Form.name]: Form,
    [Radio.name]: Radio,
    [Alert.name]: Alert,
    [Input.name]: Input,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [FormItem.name]: FormItem,
    [Checkbox.name]: Checkbox,
    [RadioGroup.name]: RadioGroup,
    [InputNumber.name]: InputNumber,
    VueQrious,
    VueHtml2pdf,
    RouteBreadCrumb,
    QueueBusterQrCode: () => import("./components/QueueBusterQrCode.vue"),
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
      getClient: 'account/getUserClient',
      getConceptSettings: 'account/getConceptSettings',
    }),
  },
  mixins: [translations, notificationAlerts],
})
export default class QueueBuster extends Vue {
  queueBuster?: Queue;
  $refs!: any;
  $notify!: any;
  getConceptSettings: any
  getClient: any
  form: FormObject = {
    branch: "",
    branchCode: "",
    branchLogo: "",
    qr_type: "",
    qrCodeCount: 1,
    printLogo: false,
    printTable: false,
    printBranch: false,
    to: 1,
    from: 1,
    "web-uri": "",
  };
  translate!: Function;
  radio: number = 1;
  branches: Array<Object> = [];
  applications: Array<ApplicationObject> = [];
  qr_type: string = "";
  qr_options: any = [
    {text: "Eat-in", value: "eat-in"},
    {text: "To-go", value: "to-go"},
    {text: "Delivery", value: "deliver"},
  ];
  dynamicTable?: any = {
    table: [1],
  };
  selectedTable: any = 1;
  currentConcept: any = "";
  isQRTriggered: boolean = false;
  coupons: any = [];
  couponSelected: any = null;
  selectedApplication: any = null;
  disableCoupon: boolean = true;
  showTable: boolean = false;

  @Watch("form.qr_type", {immediate: true, deep: true})
  onFormQRType(data: any) {
    this.form.to = 1;
    this.dynamicTable.table = [1];
    if (data == 'eat-in') {
      this.showTable = true;
    } else if (data == 'to-go') {
      this.showTable = false;
      this.form.printTable = false;
    } else if (data == 'deliver') {
      this.showTable = false;
      this.form.printTable = false;
      this.form.printBranch = false;
    }
  }

  created() {
    if(this.getClient?.attributes?.label === 'Foodics Online') {
      if(this.getConceptSettings?.attributes['is-free-package']) {
        console.log("check package", this.getConceptSettings?.attributes['is-free-package']); //debugging purpose
        this.disableCoupon = false;
        this.qr_options = [
          {text: this.translate("Delivery"), value: "deliver"}
        ];
        this.form.qr_type = "deliver";
        this.isQRTriggered = true;
      }
    } else {
              console.log("check package", this.getConceptSettings?.attributes['is-free-package']); //debugging purpose
      if(this.getConceptSettings?.attributes['is-free-package']) {
          this.disableCoupon = false;
          this.qr_options = [
            {text: this.translate("Delivery"), value: "deliver"}
          ];
          this.form.qr_type = "deliver";
          this.isQRTriggered = true;
      } else {
        this.qr_options = [
          {text: this.translate("Eat-in"), value: "eat-in"},
          {text: this.translate("To-go"), value: "to-go"},
          {text: this.translate("Delivery"), value: "deliver"},
        ];
      }

    }

  }

  mounted() {
    this.getApplications();
    this.getAllLocations(1);
    this.getCurrentConcept();
    this.getCoupons();
  }

  async getCoupons() {
    const response = await Coupon.all(1, true);
    this.coupons = response.data.data;
  }

  async getApplications() {
    const response = await Applications.getAllApplications();
    const applications = response.data.data;
    this.applications = applications.filter((item: ApplicationObject) => item.attributes["application-type"] === "social-ordering");
    this.selectedApplication = this.applications[0].id;
    let attributes = this.applications[0].attributes;
    this.form["web-uri"] = attributes["custom-domain"]
        ? attributes["custom-domain"]
        : attributes.hosts;
  }

  getAllLocations(page: 1) {
    Location.getAllLocations(page).then((response: any) => {
      this.branches = [...this.branches, ...response?.data?.data];

      if (
              response.data.meta.pagination.current_page <
              response.data.meta.pagination.total_pages
          ) {
            this.getAllLocations(response.data.meta.pagination.current_page + 1);
          }
    });
  }

  onApplicationChange() {
    let attributes = this.applications.filter((data: any) => data.id === this.selectedApplication);

    this.form["web-uri"] = attributes[0].attributes["custom-domain"]
        ? attributes[0].attributes["custom-domain"]
        : attributes[0].attributes.hosts;
    console.log("web-uri", this.form["web-uri"]);
  }

  addTable(tableNumber: number) {
    // if (this.dynamicTable.table.length === 9) {
    //   return false
    // }
    this.dynamicTable.table.push(tableNumber);
  }

  tableType(data: any) {
    this.selectedTable = data;
    if (+data === 1) {
      let tableCount = this.dynamicTable.table.length;

      this.form.from = 1;
      this.form.to = tableCount;
      this.dynamicTable.table = [];
      for (let i = 1; i <= tableCount; i++) {
        this.dynamicTable.table.push(i);
      }
    }
  }

  selectSequence() {
    if (this.form.to < this.form.from) {
      this.$notify({
        title: this.translate("TABLE NUMBERS INCORRECT"),
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: this.translate(
            "Table FROM number must not be greater than Table number TO."
        ),
        type: "danger",
        icon: "fas fa-bomb",
      });
    }

    this.dynamicTable.table = [];
    for (let i = this.form.from; i <= this.form.to; i++) {
      if (this.form.to > 100000) {
        this.$notify({
          title: this.translate("QR Code Limit"),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: this.translate(
              "You can only generate up to 100,000 QR Codes"
          ),
          type: "danger",
          icon: "fas fa-bomb",
        })
        return false
      }
      this.addTable(i);
    }
  }

  selectQRType() {
    this.form.qrCodeCount = 1;
    this.showBranchDetails(this.branches[0])
  }

  showBranchDetails(data: any) {
    let findBranch: any = this.branches.filter(
        (branch: any) => branch.id == data ? data : data.id
    )[0];
    if (data && data.id) {
      this.form.branch = data.id
    }
    this.form.branchCode = findBranch.attributes.name;
    this.isQRTriggered = true;
  }

  async getCurrentConcept() {
    await Concept.default().then((response: any) => {
      console.log('getCurrentConcept: ', response.data.data);
      this.currentConcept = response.data.data.attributes.label;
      this.form.branchLogo = response.data.data.attributes["logo-uri"]
          ? response.data.data.attributes["logo-uri"]
          : null;
    });
  }

  removeTable(item: any) {
    let index = this.dynamicTable.table.indexOf(item);
    if (index !== -1) {
      this.dynamicTable.table.splice(index, 1);
    }
  }



 async generateReport() {
    QueueBusterApi.download(this.form.qr_type, this.form.qrCodeCount, this.form.branch, this.form.branchCode, this.form.from, this.form.to, this.form.printLogo, this.form.printBranch, this.form.printTable, this.dynamicTable.table, this.selectedApplication).then((response: any) => {
      /* @ts-ignore */
      analytics.track('create_qr', {
        qr_type: this.form.qr_type,
        has_coupon: this.couponSelected ? true : false,
      });

      window.open(response.data.data.attributes['csv-uri'], '_blank');
    });
  }


  getCode(couponSelected: string) {
    let code = null;

    if (this.coupons && Array.isArray(this.coupons)) {
      const coupon = this.coupons.find(c => c.id === couponSelected);
      if (coupon) {
        return coupon.attributes.code;
      }
    }

    return code;
  }
}
